<script>
  import { Icon, Plus } from 'svelte-hero-icons'
  export let citizens
</script>

<h1>Borgere</h1>
<a href="/citizens/new" class="btn btn-default" style="margin-bottom: 2rem;">
  Opret ny borger
</a>

{#each citizens as citizen}
  <div class="panel panel-default">
    <a href="/citizens/{citizen.id}">
      <div class="panel-body">
        <img src={citizen.avatar.navbar_thumb.url} class="img-circle" />
        {citizen.name}
      </div>
    </a>
  </div>
{/each}

<script>
  import Button from '@/components/Button.svelte'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import { boardStore, reloadTasks } from '@/stores/TaskBoardStore'
  import { modalStore as userGrabbingModalStore } from '@/stores/ModalStore'
  import { onDestroy, onMount } from 'svelte'
  import axios from 'axios'

  $: users = []

  onMount(() => {
    $boardStore.safeToReload = false
  })

  onDestroy(() => {
    $boardStore.safeToReload = true
  })

  axios
    .get('/user/checked_in')
    .then((response) => {
      users = response.data
    })
    .catch((error) => {
      console.log(error)
    })
</script>

<div class="user-box-container">
  {#if users.length > 0}
    {#each users as user}
      <Button width={'250px'}>
        <div
          class="user-box-button"
          on:click={() => {
            $boardStore.userGrabbingTasks = user
            $boardStore.taskGrabbingActivated = true
            userGrabbingModalStore.closeModal()
            reloadTasks()
          }}
        >
          <UserAvatar {user} />
          <div>{user.name}</div>
        </div>
      </Button>
    {/each}
  {:else}
    <div>Ingen medarbejdere er tjekket ind på arbejdet</div>
  {/if}
</div>

<style>
  .user-box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
  }

  .user-box-button {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
</style>

<script>
  import { useForm } from '@inertiajs/svelte'

  export let company
  export let slug

  let form = useForm({
    slug: company ? company.slug : slug,
  })

  function handleSubmit() {
    $form.post('/i?slug=' + $form.slug)
  }
</script>

<div class="lead text-center">Log ind med en licenskode</div>
<form on:submit|preventDefault={handleSubmit}>
  <div class="form-group {$form.errors.slug ? 'has-error' : ''}">
    <input
      type="text"
      class="form-control input-lg text-center"
      id="slug"
      bind:value={$form.slug}
      autofocus
      placeholder="Indtast licenskoden for din afdeling"
    />
    {#if $form.errors.slug}
      <p class="help-block">{$form.errors.slug}</p>
    {/if}
  </div>
  <div class="form-group">
    <button type="submit" class="btn btn-primary btn-lg btn-block"
      >Log ind</button
    >
  </div>
</form>
<br />
<br />
<div class="text-center lead">eller</div>
<br />
<br />
<div class="hidden-xs">
  <a href="/saml/login" class="btn btn-default btn-lg btn-block">
    <div class="glyphicon glyphicon-log-in" />
    Log ind med kommunalt Single Sign-On
  </a>
</div>
<div class="visible-xs">
  <a href="/saml/login" class="btn btn-default btn-lg btn-block">
    <div class="glyphicon glyphicon-log-in" />
    Log ind med kommunalt
    <br />
    Single Sign-On
  </a>
</div>
<div class="text-center" style="margin-top: 30px">
  <a href="/saml/reports/new" class="text-muted">
    Problemer med at komme ind via Single Sign-On? Klik her
  </a>
</div>

<script>
  export let report = {}
  export let privileges = []
</script>

<div class="container">
  <div class="lead">
    <strong>
      Rapporten er nu oprettet og kan deles med din IT afdeling eller TeamEffect
      ved at dele linket:
      <br />
      <a href={window.location}>{window.location}</a>
    </strong>
  </div>
  <p>
    Du kan dele rapporten med TeamEffect ved at sende du sende ovenstående link
    til
    <a href="mailto:support@teameffect.io">support@teameffect.io</a> hvor du også
    kan beskrive problemet du oplever.
  </p>
  <hr />
  <h1>SAML rapport</h1>
  <div>
    <strong>Id:</strong>
    {report.slug}
  </div>
  <div>
    <strong>Oprettet d.</strong>
    {report.created_at}
  </div>

  <div style="margin-top: 50px">
    <h2>Attributter og rettigheder</h2>

    <strong>Rettigheder</strong>
    <ul>
      {#each privileges as privilege}
        <li>{privilege}</li>
      {/each}
    </ul>
    {#if privileges.length === 0}
      <p>Der blev ikke fundet nogen rettigheder</p>
    {/if}

    <strong>Attributter</strong>
    <div style="word-wrap: break-word;">
      {report.decoded_saml_attributes}
    </div>
  </div>

  <div style="margin-top: 50px; margin-bottom: 200px">
    <h2>SAML respons</h2>
    <div style="word-wrap: break-word;">
      {report.decoded_saml_response}
    </div>
  </div>
</div>

<script>
  import {
    Icon,
    PlusCircle,
    XCircle,
    CheckCircle,
    ArrowPath,
  } from 'svelte-hero-icons'
  import './floweffect.css'
  import { boardStore, updateBoard, reloadTasks } from '@/stores/TaskBoardStore'
  import { userStore } from '@/stores/UserStore'
  import { citizenStore } from '@/stores/CitizenStore'
  import Modal from '@/components/Modal.svelte'
  import TaskForm from './components/TaskForm.svelte'
  import Task from './components/Task.svelte'
  import CheckInModal from './components/CheckInModal.svelte'
  import TaskGrabbingUserModal from './components/TaskGrabbingUserModal.svelte'
  import TaskBoard from './components/TaskBoard.svelte'
  import { headerTemplate } from './components/headerTemplate'
  import DateSelection from './components/DateSelection.svelte'
  import BoardSelection from './components/BoardSelection.svelte'
  import { getUrlTimeParams } from './utils'
  import { modalStore as userGrabbingModalStore } from '@/stores/ModalStore'
  import axios from 'axios'
  import CitizenObjectives from './components/CitizenObjectives.svelte'
  import CitizenFlagMessages from './components/CitizenFlagMessages.svelte'

  export let citizens
  export let tasks
  export let allUsers
  export let citizenSelected

  let showNewTaskModal = false
  let showCheckInModal = false
  let boardTasks = []

  $boardStore.boardType = 'citizen'

  // This is used to force the board to update
  // whenever a task is upodated - a bit hacky
  // TODO: Should be refactored
  boardStore.subscribe((store) => {
    if (
      JSON.stringify(boardTasks.map((task) => task.id).sort()) !=
      JSON.stringify(store.boardTasks.map((task) => task.id).sort())
    ) {
      boardTasks = store.boardTasks
    }
  })

  function handleTaskGrabbingButtonClick() {
    if ($boardStore.taskGrabbingActivated) {
      postGrabbedTasks()
    } else {
      userGrabbingModalStore.openModal()
    }
  }

  function postGrabbedTasks() {
    let grabbedTasksData = { tasks: { user_id: null, task_ids: null } }
    let taskIds = []
    $boardStore.grabbedTasks.forEach((taskId) => {
      taskIds.push($boardStore.boardTasks.find((t) => t.id === taskId).task_id)
    })

    grabbedTasksData.tasks.user_id = $boardStore.userGrabbingTasks.id
    grabbedTasksData.tasks.task_ids = taskIds

    axios
      .post('/floweffect/tasks/grab_tasks', grabbedTasksData)
      .then(() => {
        updateBoard()
        boardStore.resetGrabbedTasks()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function formattedCitizenData(citizen) {
    return {
      id: citizen.id,
      headerHtml: headerTemplate(
        citizen,
        citizen.flag_messages,
        citizenSelected
          ? `/citizen_board${getUrlTimeParams($boardStore)}`
          : `/citizen_board${getUrlTimeParams($boardStore)}&citizen_id=${
              citizen.id
            }`,
      ),
    }
  }

  function initializeData() {
    let formattedCitizens = []
    let formattedTasks = []
    $userStore = allUsers
    $citizenStore = citizens

    citizens.forEach((citizen) => {
      formattedCitizens.push(formattedCitizenData(citizen))
    })

    updateBoard() // TODO: Should be refactored, no data should be sent to board from controller

    $boardStore.rows = formattedCitizens
    $boardStore.tasks = tasks
    $boardStore.boardTasks = formattedTasks
    $userStore = allUsers
    $citizenStore = citizens
  }

  initializeData()

  function closeModal() {
    showNewTaskModal = false
  }

  $: console.log($boardStore)
</script>

<!-- Board selection -->
<BoardSelection board={'citizen'} />
<div
  style="display: flex; justify-content: {citizenSelected
    ? 'space-between'
    : 'right'}"
>
  {#if citizenSelected}
    <div style="padding-left: 20px;">
      <a
        href="/citizen_board{getUrlTimeParams($boardStore)}"
        class="btn btn-default"
      >
        <div class="glyphicon glyphicon-chevron-left" />
        Tilbage til oversigt
      </a>
    </div>
  {/if}
  <DateSelection
    startTime={$boardStore.startTime}
    endTime={$boardStore.endTime}
  />
</div>

<!-- Gantt chart -->
{#key boardTasks}
  <TaskBoard />
{/key}

{#if citizenSelected}
  <CitizenFlagMessages
    citizen={citizenSelected}
    flags={citizenSelected.flag_messages}
  />
  <CitizenObjectives
    citizen={citizenSelected}
    objectives={citizenSelected.objectives}
  />
{/if}

<!-- Green corner buttons -->
<div class="corner-btns">
  {#if true}
    <button
      class="corner-btn green-corner-btn"
      on:click={() => {
        handleTaskGrabbingButtonClick()
      }}
    >
      {#if $boardStore.grabbedTasks.length > 0}
        <Icon src={CheckCircle} size="36" />
        Gem {$boardStore.grabbedTasks.length} opgaver
      {:else}
        <Icon src={PlusCircle} size="36" />
        Tag opgaver
      {/if}
    </button>
  {/if}
  {#if $boardStore.taskGrabbingActivated}
    <button
      class="corner-btn red-corner-btn"
      on:click={() => {
        $boardStore.taskGrabbingActivated = false
        boardStore.resetGrabbedTasks()
        reloadTasks()
      }}
    >
      <Icon src={XCircle} size="36" />
      Annuller
    </button>
  {/if}
  <button
    class="corner-btn green-corner-btn new-task-btn"
    on:click={() => {
      showNewTaskModal = !showNewTaskModal
    }}
  >
    <Icon src={PlusCircle} size="36" />
    Ny opgave
  </button>
  <button
    class="corner-btn green-corner-btn check-in-btn"
    on:click={() => {
      showCheckInModal = !showCheckInModal
    }}
  >
    <Icon src={CheckCircle} size="36" />
    Tjek ind / ud
  </button>
</div>

<!-- Modals -->
<Modal bind:showModal={showNewTaskModal}>
  <span slot="header">Opret ny opgave</span>

  <TaskForm {closeModal} />
</Modal>

<Modal bind:showModal={$boardStore.showTaskModal}>
  <div slot="header">
    <div>
      Opgave: {$boardStore.selectedTask.name}
      {#if $boardStore.selectedTask.recurring}
        <span class="badge badge-pill badge-primary">
          <Icon src={ArrowPath} size="12" />
          Gentagende</span
        >
      {/if}
    </div>
  </div>

  <Task />
</Modal>

<Modal bind:showModal={showCheckInModal}>
  <span slot="header">Tjek ind / ud</span>

  <CheckInModal users={allUsers} bind:showModal={showCheckInModal} />
</Modal>

<Modal bind:showModal={$userGrabbingModalStore.showModal}>
  <span slot="header">Hvem skal sættes på opgaverne?</span>
  <TaskGrabbingUserModal />
</Modal>

<style>
  .corner-btn {
    z-index: 100;
    border: none;
    background-color: #64cc7c;
    color: white;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 20px;
  }

  .green-corner-btn:hover,
  .green-corner-btn:active {
    background-color: #4e9e5d;
  }

  .corner-btns {
    display: flex;
    gap: 1rem;
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    z-index: 100;
  }

  .red-corner-btn {
    background-color: #f92121;
  }
</style>

<script>
  import UserAvatar from '@/components/UserAvatar.svelte'
  import { createEventDispatcher } from 'svelte'
  import { page } from '@inertiajs/svelte'
  import Button from '@/components/Button.svelte'
  import { Icon, XCircle, CheckCircle } from 'svelte-hero-icons'
  import axios from 'axios'
  import dayjs from 'dayjs'
  import { boardStore, updateBoard } from '@/stores/TaskBoardStore'

  export let user
  export let checkInLog
  export let resetUser = () => {}
  let submitting = false

  const dispatch = createEventDispatcher()

  function checkIn() {
    submitting = true
    axios
      .post('/check_in_log/check_ins', {
        check_in: {
          user_id: user.id,
          check_in_time: dayjs(),
          company_id: $page.props.auth.company.id,
        },
      })
      .then((response) => {
        console.log(response)
        dispatch('updateCheckIns')
        resetUser()
      })
      .catch((error) => {
        resetUser()
        console.log(error)
      })
  }

  function checkOut() {
    submitting = true
    axios
      .put('/check_in_logs/' + checkInLog.id, {
        check_in_log: {
          id: checkInLog.id,
          check_out_time: dayjs(),
        },
      })
      .then((response) => {
        console.log(response)
        dispatch('updateCheckIns')
        resetUser()
      })
      .catch((error) => {
        resetUser()
        console.log(error)
      })
  }

  function handleUserNotPresent() {
    submitting = true
    axios
      .post(`/users/${user.id}/out_of_works/`, {
        start_time: $boardStore.startTime,
        end_time: $boardStore.endTime,
      })
      .then(() => {
        updateBoard()
        resetUser()
      })
      .catch((error) => {
        console.log(error)
      })
  }
</script>

<div class="user-options-container">
  <h3>
    <UserAvatar {user} />
    {user.name}
  </h3>
  {#if checkInLog}
    <Button variant={'success'} width={'250px'} {submitting}>
      <div
        class="option-button"
        on:click={() => {
          !submitting ? checkOut() : null
        }}
      >
        <Icon src={CheckCircle} size="24" />
        Tjek ud
      </div>
    </Button>
  {:else}
    <Button variant={'success'} width={'250px'} {submitting}>
      <div
        class="option-button"
        on:click={() => {
          !submitting ? checkIn() : null
        }}
      >
        <Icon src={CheckCircle} size="24" />
        Tjek ind
      </div>
    </Button>
    <Button variant={'danger'} width={'250px'} {submitting}>
      <div class="option-button" on:click={handleUserNotPresent}>
        <Icon src={XCircle} size="24" />
        Ikke tilstede
      </div>
    </Button>
  {/if}
  <Button width={'250px'}>
    <div class="option-button" on:click={resetUser()}>Tilbage</div>
  </Button>
</div>

<style>
  .user-options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  .option-button {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
</style>
